import { Card, message, Modal } from 'antd';
import { PageProps } from 'gatsby';
import { camelCase, startCase } from 'lodash';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Layout, { LayoutProps } from '../../components/Layout';
import { QuickForm } from '../../components/QuickForm';
import { Spinner } from '../../components/Spinner';
import config from '../../modules/dynamic-config';
import { useKeyData } from '../../modules/hasura/useKeyData';
import { getFormFields } from '../../modules/row-data/utils';

export default function SettingPage(props: PageProps) {
  const { name } = props.params;
  const settingKey = `settings.${camelCase(name)}`;
  const { save, data, loaded, loading: submitting } = useKeyData(settingKey);

  const colFields = useMemo(() => {
    return config.getColFields(name);
  }, [name]);

  if (!colFields) {
    return 'Not colFields setting found';
  }

  const layoutProps = useMemo<Partial<LayoutProps>>(() => {
    const title = startCase(name);
    return {
      pageTitle: title,
      breadcrumb: [
        { title: 'Home', path: '/' },
        { title: title, path: `/${title}/list` },
      ],
    };
  }, [name]);

  const fields = useMemo(() => {
    return getFormFields({ colFields } as any, 'update');
  }, [colFields]);

  const initialValues = data;

  const onFinish = (values: any) => {
    save({
      set: values,
    })
      .then((result) => {
        Modal.success({
          content: 'Submit successful',
        });
      })
      .catch((error) => {
        message.error(error.toString(), 3);
      });
  };

  return (
    <Layout {...layoutProps} key={settingKey}>
      <Helmet title={startCase(name)} />
      <Card bodyStyle={{ paddingBottom: 0 }} extra={!loaded && <Spinner />}>
        {loaded && (
          <QuickForm
            layout="vertical"
            name="basic"
            fields={fields}
            initialValues={initialValues}
            onFinish={onFinish}
            actionButtons={[
              {
                children: 'Submit',
                htmlType: 'submit',
                type: 'primary',
                loading: submitting,
                disabled: submitting,
              },
            ]}
          />
        )}
      </Card>
    </Layout>
  );
}
